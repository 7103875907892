import PhototypeTemplate from '@/modules/questionnaire/components/steps/common/identity/phototype/PhototypeTemplate.vue';

import { SKIN_COLOR, SKIN_TYPE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Identity/Phototype/PhototypeTemplate',
  component: PhototypeTemplate
};

const createStory = props => () => ({
  components: { PhototypeTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><phototype-template v-bind="$options.storyProps" /></div>'
});

export const skinTypeAlwaysBurnSkinColorVeryPaleWhite = createStory({
  skinType: SKIN_TYPE.ALWAYS_BURN,
  skinColor: SKIN_COLOR.VERY_PALE_WHITE
});

export const skinColorPaleWhite = createStory({
  skinType: '',
  skinColor: SKIN_COLOR.PALE_WHITE
});

export const skinColorWhite = createStory({
  skinType: '',
  skinColor: SKIN_COLOR.WHITE
});

export const skinColorLightBrown = createStory({
  skinType: '',
  skinColor: SKIN_COLOR.LIGHT_BROWN
});

export const skinColorMediumBrown = createStory({
  skinType: '',
  skinColor: SKIN_COLOR.MEDIUM_BROWN
});

export const skinColorDarkBrown = createStory({
  skinType: '',
  skinColor: SKIN_COLOR.DARK_BROWN
});

export const skinColorBlack = createStory({
  skinType: '',
  skinColor: SKIN_COLOR.BLACK
});
